//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Dropdown",
  props: {
    btnId: {
      type: String,
      required: true,
    },
    btnClass: {
      type: String,
      required: false,
      default: 'px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple',
    },
    dropdownContentClass: {
      type: String,
      required: false,
      default: 'absolute hidden w-56 p-2 mt-2 border rounded-md shadow-md border-gray-700 text-gray-300 bg-gray-700',
    },
  },
}
