//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from "~/components/layouts/Header";
import Navbar from "~/components/layouts/Navbar";

export default {
  name: "default",
  components: {Navbar, Header},
  data() {
    return {
      version: this.$config.appVersion,
    }
  },
}
