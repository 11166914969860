//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dropdown from "~/components/plugins/Dropdown";
import VueScrollbox from 'vue-scrollbox';

export default {
  name: "Header",
  components: {Dropdown, VueScrollbox},
  data() {
    return {
      searchText: null,
      cancelSource: null,
      searchBoxIsActive: false,
      searchResponse: {
        sites: [],
        pages: [],
        keywords: [],
      },
      showMobileMenu: false,
    }
  },
  computed: {
    isEmptySearchSites() {
      if (this.searchResponse.sites.length === 0) {
        return true;
      }

      return false;
    },
    isEmptySearchPages() {
      if (this.searchResponse.pages.length === 0) {
        return true;
      }

      return false;
    },
    isEmptySearchKeywords() {
      if (this.searchResponse.keywords.length === 0) {
        return true;
      }

      return false;
    },
    getSearchBoxHeight() {
      let sites = this.searchResponse.sites.length;
      let pages = this.searchResponse.pages.length;
      let keywords = this.searchResponse.keywords.length;
      let sum = sites + pages + keywords;

      if (sum < 10) {
        return (sum * 58) + 'px';
      } else {
        return '400px';
      }
    },
  },
  mounted() {
    this.$root.$on('closeMobileMenu', () => {
      this.closeMobileMenu();
    });
  },
  methods: {
    async logout() {
      await this.$auth.logout();
    },
    getSearch() {
      this.cancelSearch();
      this.cancelSource = this.$axios.CancelToken.source();

      this
        .$axios
        .get('/searches?q=' + this.searchText, {
          cancelToken: this.cancelSource.token
        })
        .then((response) => {
          if (response) {
            this.searchResponse.sites = response.data.sites;
            this.searchResponse.pages = response.data.pages;
            this.searchResponse.keywords = response.data.keywords;
            this.cancelSource = null;
            this.searchBoxIsActive = true;
          }
        })
        .catch((e) => {

        })
    },
    closeSearch() {
      this.searchText = '';
      this.searchBoxIsActive = false;
    },
    cancelSearch () {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
    },
    openMobileMenu() {
      this.showMobileMenu = true;
      this.$root.$emit('switchMobileMenu', {
        value: true,
      });
    },
    closeMobileMenu() {
      this.showMobileMenu = false;
      this.$root.$emit('switchMobileMenu', {
        value: false,
      });
    },
  },
  watch: {
    searchText(value) {
      if (value.length > 2) {
        this.getSearch();
      } else {
        this.searchBoxIsActive = false;
      }
    }
  }
}
