//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Navbar",
  mounted() {
    this.$root.$on('switchMobileMenu', (e) => {
      if (e.value) {
        this.mobileClass = 'absolute z-10 h-1/3 pt-4 -mt-2';
      } else {
        this.mobileClass = 'hidden';
      }
    });
  },
  data() {
    return {
      mobileClass: 'hidden',
    }
  },
  computed: {
    getCurrentPage() {
      let segments = this.$route.path.split('/');

      return (typeof segments[2] !== 'undefined') ? segments[2] : '';
    },
  },
  methods: {
    closeMobileMenu() {
      this.$root.$emit('closeMobileMenu');
    },
  },
}
