export default function({$axios, store, app}) {
  $axios.onError(error => {
    if (error.response.status === 422) {
      store.dispatch(
        'validation/setErrors',
        error.response.data.errors
      );
    }

    if (error.response.status === 401) {
      app.router.push('/app');
    }

    if (error.response.status === 404) {
      app.router.push('/app');
    }

    if (error.response.status === 403) {
      app.router.push('/app');
    }

    return Promise.reject(error);
  })

  $axios.onRequest(() => {
    store.dispatch('validation/clearErrors');
  })
}
