//
//
//
//
//
//
//
//
//
//

export default {
  name: "auth"
}
