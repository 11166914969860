export const state = () => ({
  site: {}
})

export const getters = {
  site(state) {
    return state.site;
  }
}

export const mutations = {
  SET_SITE(state, site) {
    state.site = site;
  }
}

export const actions = {
  setSite({commit}, site) {
    commit('SET_SITE', site);
  },
}
